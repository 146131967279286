import appRoutes from '../../view/routing';
import invoicingRoutes from '../../view/routing/InvoicingBase/routes';
import dashboardRoutes from '../../view/routing/DashboardBase/routes';
import administrationRoutes from '../../view/routing/AdministrationBase/routes';
import paymentsRoutes from '../../view/routing/PaymentsBase/routes';

export const breadcrumbsRoutes = [
    ...invoicingRoutes,
    ...dashboardRoutes,
    ...administrationRoutes,
    ...appRoutes,
    ...paymentsRoutes
];

export default [
    ...appRoutes,
];
