import React, { Fragment, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Link, useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ChevronRight from "@material-ui/icons/ChevronRightOutlined";
import ExpandMore from "@material-ui/icons/ExpandMoreOutlined";
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import clsx from "clsx";
import { useSelector } from "react-redux";
import logo from "../../logo.png";

import useStyles from "./style.js";
import { useTranslation } from "react-i18next";
import { hasPermission, hasRoles } from "../../../utils/auth";
import { Typography } from "@material-ui/core";

export default function ({ menu = [] }) {
    const user = useSelector(({ Auth }) => Auth.user);
    const resourceRoles = useSelector(({ keycloak }) => keycloak.resourceAccess.novyclient?.roles);
    const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
    const headerExpanded = useSelector(({ appState }) => appState.headerExpanded);
    const { t } = useTranslation();
    const classes = useStyles(menuExpanded, headerExpanded);
    const { pathname } = useLocation();

    const [openSubMenu, setOpenSubMenu] = useState(
        menu.map((m, index) => ({index, data: m}))
            .filter(m => !!m.data.expanded || matchPath(pathname, {path: m.data.pathFormat ?? m.data.path, exact: true, strict: false})?.isExact)
            .map(m => m.index)
    );

    const onListClick = (items, index) => {
        if (items) {
            setOpenSubMenu(openSubMenu.includes(index) ? openSubMenu.filter(x => x !== index) : [...openSubMenu, index]);
        }
    };

    const authorizedMenu = () => {
        return menu.filter(m => hasPermission(user.permissions, m.permissions) && hasRoles(resourceRoles, m.roles));
    };

    const authorizedSubMenu = (items) => {
        return items.filter(m => hasPermission(user.permissions, m.permissions) && hasRoles(resourceRoles, m.roles));
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="left"
            classes={{
                paper: clsx(
                    classes.menuLeft,
                    !menuExpanded && classes.menuLeftClose,
                    headerExpanded && classes.menuLeftExpanded
                ),
            }}
            open={menuExpanded}
        >
            <div className={classes.container}>
                <div className={classes.logo}>
                    {!headerExpanded && <img src={logo} alt="Logo" />}
                </div>
                <List>
                    {authorizedMenu().map(({ name, icon: Icon, path = "", pathFormat, clickable, items }, index) => (
                        <Fragment key={index}>
                            <ListItem
                                button
                                component={Link}
                                onClick={(e) => {
                                    !clickable && e.preventDefault(); 
                                    onListClick(items, index);
                                }}
                                to={path}
                                className={clsx(classes.menuListItem, matchPath(pathname, {path: pathFormat ?? path, exact: true, strict: false})?.isExact && classes.selected)}
                            >
                                <ListItemIcon className={classes.icon} >
                                    <Icon className={classes.svg} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography 
                                        noWrap
                                        title={t(name)} 
                                        className={classes.menuItemText}
                                    >
                                        {t(name)}
                                    </Typography>
                                </ListItemText>
                                {items && (!openSubMenu.includes(index) ? <ChevronRight /> : <ExpandMore />)}
                            </ListItem>
                            {
                                menuExpanded && 
                                items && 
                                <Collapse in={openSubMenu.includes(index)} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className={classes.nested}>
                                        {authorizedSubMenu(items).map(({ name, path, pathFormat }) => (
                                            <ListItem
                                                key={path}
                                                button
                                                component={Link}
                                                to={path}
                                                className={classes.menuListItem}
                                            >
                                                <ListItemIcon className={classes.icon}>
                                                    {
                                                        matchPath(pathname, {path: pathFormat ?? path, exact: true, strict: false})?.isExact
                                                            ? <FiberManualRecordIcon className={classes.svg} />
                                                            : <FiberManualRecordOutlinedIcon className={classes.svg} />
                                                    }
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography 
                                                        noWrap
                                                        title={t(name)} 
                                                        className={classes.menuItemText}
                                                    >
                                                        {t(name)}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            }
                        </Fragment>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}
