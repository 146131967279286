import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
    TextField,
    Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';


// type : create, edit, filter
function ResponsableSelect({
    type,
    value,
    handleValueChange,
    variant = "standard",
    disabled = false,
    size = "medium",
    selectedClient,
    selectedProject,
    label,
    inputStyle
}) {

    const { t } = useTranslation();
    const classes = useStyles();
    const responsables = useSelector(({ collaborators }) => collaborators.responsables);
    const currentCollaborator = useSelector(({ collaborators }) => collaborators.currentCollaborator);

    const isCollabAllowed = (collab) => {
        if (collab.id === currentCollaborator.id && currentCollaborator.isAdmin) {
            return 1;
        } else if ((selectedClient && collab.clients.includes(selectedClient.id))
            || (selectedProject && (collab.projects.includes(selectedProject.id)
                || collab.clients.includes(selectedProject.client?.id)))) {
            return 1;
        } else return 0;
    };

    const adjustOptions = (options) => {
        if (options && type !== "filter" && (selectedClient || selectedProject)) {
            let localOptions = options.map(collab => { return { ...collab, allowed: isCollabAllowed(collab), section: isCollabAllowed(collab) ? "Autorisés" : "Non autorisés" }; });
            return localOptions.sort((a, b) => b.allowed - a.allowed || a.firstName?.localeCompare(b.firstName));
        } else if (options) {
            return options.sort((a, b) => {
                if(!a.firstName){
                    a.firstName = ""
                }
                return a.firstName?.localeCompare(b.firstName);
            });
        }
        return null;
    };

    const renderCollabName = (collab) => {
        if (type === "filter") {
            return `${collab.firstName} ${collab.lastName}`;
        } else {
            if (collab.allowed) {
                return <span><CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} /> {`${collab.firstName} ${collab.lastName}`}</span>;
            } else if (selectedClient || selectedProject) {
                return <span><BlockIcon fontSize="small" color="error" /> {`${collab.firstName} ${collab.lastName}`}</span>;
            } else {
                return `${collab.firstName} ${collab.lastName}`;
            }
        }
    };

    return (
        <Autocomplete
            value={value}
            options={type !== "create" ? adjustOptions(responsables) : adjustOptions(responsables.filter(c => c.activated))}
            onChange={(e, val) => handleValueChange(val)}
            autoHighlight
            getOptionLabel={(collab) => `${collab.firstName} ${collab.lastName}`}
            getOptionSelected={(option, value) => option.id === value?.id}
            renderOption={(collab) =>
                !collab.activated
                    ? <>
                        <Typography color="textSecondary">
                            {renderCollabName(collab)} <span style={{ fontSize: "0.7rem", color: "Tomato" }}>[{t('collab_archived')}]</span>
                        </Typography>
                    </>
                    : renderCollabName(collab)
            }
            groupBy={(collab) => type !== "filter" && (selectedClient || selectedProject) ? collab.section : null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant={variant}
                    size={size}
                    label={label || t('responsable_col')}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_collaborator_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            disabled={disabled}
            classes={{ paper: classes.paper }}
        />
    );

}

export default ResponsableSelect;