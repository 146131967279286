import React, { Fragment, Suspense, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import navs from "./navs";
import AppBar from "./components/AppBar";
import MenuLeft from "./components/MenuLeft";
import useStyles from "./style.js";
import { ThemeProvider } from "@material-ui/styles";
import getTheme from "../style/theme";
import Loading from "./components/Loading";
import { getCurrentUser } from "../view/redux/actions/auth";
import Swal from "sweetalert2";
import Breadcrumbs from "./components/Breadcrumbs";
import appRoutes, { breadcrumbsRoutes } from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import { Hidden, useMediaQuery } from "@material-ui/core";
import NotSupportedIcon from "../App/assets/not_supported.png";
import LogoColorful from "./logo_colorful.png";
import { setScreenMedia } from "./appSlice";
import { ReactENV } from "../utils/io";
import { RoutingWithAuth } from "../utils/routing";
import { ADMIN_INVOICING_ROLE, MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE } from "../utils/constants";
import { getCurrentCollaborator } from "../view/redux/actions/collaborators";
import { hasRoles } from "../utils/auth.js";

const Copyright = () => {
    const { t } = useTranslation();
    return (
        <Box pb={2}>
            <hr></hr>
            <Typography variant="body2" color="textSecondary" align="center">
                {t("Copyright")} ©
                <Link
                    color="inherit"
                    variant="body2"
                    href="https://novelis.io"
                    target="_blank"
                >
                    Novelis Innovation
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        </Box>
    );
};

const UserNotFound = () => {
    return (
        <Box display="flex" alignItems="center" flexDirection="column" gridGap="20px" my={10}>
            {/* <ErrorIcon fontSize="large" color="error" />
            <Typography variant="h4">An error has occured !</Typography>
            <Typography>Connected user could not be found</Typography> */}
        </Box>
    );
};

function Main({ user, ...props }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const resourceRoles = useSelector(({ keycloak }) => keycloak.resourceAccess.novyclient?.roles);
    const authorizedRoles = [ADMIN_INVOICING_ROLE, MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE];
    const authorized = hasRoles(resourceRoles, authorizedRoles);

    if (user && !authorized) {
        Swal.fire({
            title: t("unauthorized_access_title"),
            text: t("you_are_not_authorized_to_access"),
            reverseButtons: true,
            confirmButtonText: t("ok"),
        }).then(() => {
            window.location.href = ReactENV.REACT_APP_NOVY_WEBAPP_URL;
        });
    }

    return (
        <>
            <Hidden mdUp>
                <Container maxWidth="sm">
                    <Box pt={10} textAlign="center">
                        <img
                            src={NotSupportedIcon}
                            alt=""
                            width="150px"
                            style={{ paddingBottom: "10px" }}
                        ></img>
                        <Typography
                            variant="h5"
                            gutterBottom={true}
                            style={{ paddingBottom: "5px" }}
                        >
                            {t("screen_size_not_supported_title")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {t("screen_size_not_supported_description1")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {t("screen_size_not_supported_description2")}
                        </Typography>
                        <hr style={{ margin: "20px ​0px" }} />
                        <img src={LogoColorful} alt="" width="75px"></img>
                    </Box>
                </Container>
            </Hidden>
            <Hidden smDown>
                <Fragment>
                    <CssBaseline />
                    <div className={classes.root}>
                        {authorized && (
                            <>
                                <AppBar />
                                <div className={classes.wrapper}>
                                    <MenuLeft menu={navs} />
                                    <main className={classes.content}>
                                        <Container
                                            maxWidth={false}
                                            className={classes.container}
                                        >
                                            <Breadcrumbs
                                                className="bg-transparent"
                                                appRoutes={breadcrumbsRoutes}
                                            />
                                            <Suspense fallback={<Loading />}>
                                                <ScrollToTop />
                                                <RoutingWithAuth
                                                    routes={appRoutes}
                                                    basePath="/"
                                                    homePath="/dashboard"
                                                    {...props}
                                                />
                                            </Suspense>
                                        </Container>
                                        <div className={classes.copyright}>
                                            <Copyright />
                                        </div>
                                    </main>
                                </div>
                            </>
                        )}
                    </div>
                </Fragment>
            </Hidden>
        </>
    );
}

function App() {
    const dispatch = useDispatch();
    const themeColor = useSelector(({ appState }) => appState.themeColor);
    const isKeycloakAuthenticated = useSelector(
        (state) => state.keycloak.authenticated
    );
    const tokenParsed = useSelector((state) => state.keycloak.tokenParsed);
    const { user, loading } = useSelector((state) => state.Auth);

    const { t } = useTranslation();
    const direction = t("ltr");
    const theme = getTheme(themeColor, direction);
    const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
    const lgMatch = useMediaQuery(theme.breakpoints.up("lg"));
    const mdMatch = useMediaQuery(theme.breakpoints.up("md"));
    const smMatch = useMediaQuery(theme.breakpoints.up("sm"));

    useEffect(() => {
        if (isKeycloakAuthenticated) {
            dispatch(getCurrentUser());
            dispatch(getCurrentCollaborator(tokenParsed.email));
        }
    }, [isKeycloakAuthenticated]); //eslint-disable-line react-hooks/exhaustive-deps

    const renderPilotageLayout = () => {
        if (loading && isKeycloakAuthenticated) return <Loading />;
        if (!loading && !user.id) return <UserNotFound />;

        return <Main user={user} />;
    };

    useEffect(() => {
        if (xlMatch) {
            dispatch(setScreenMedia("xl"));
        } else if (lgMatch) {
            dispatch(setScreenMedia("lg"));
        } else if (mdMatch) {
            dispatch(setScreenMedia("md"));
        } else if (smMatch) {
            dispatch(setScreenMedia("sm"));
        } else {
            dispatch(setScreenMedia("xs"));
        }
    }, [xlMatch, lgMatch, mdMatch, smMatch]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider theme={theme}>{renderPilotageLayout()}</ThemeProvider>
    );
}

export default App;
