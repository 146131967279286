import moment from "moment";
import { isAVOIREE, isEMISE, isREGLEE } from "../invoice-helper";
import { sameObjectId } from "./";

/**
 * Invoice form template
 */
export const INVOICE_TEMPLATE = {
    label: "",
    triggerAction: "",
    status: null,
    issueDate: null,
    expectedDate: null,
    settlementDate: null,
    expectedSettlementDate: null,
    invoiceOrder: {
        reference: null,
        orderItems: [
            {
                displayIndex: 0,
                reference: "",
                description: "",
                quantity: 0,
                isPercent: true,
                unitPrice: 0.00
            }
        ]
    },
    client: null,
    project: null,
    responsable: null,
    invoiceType: null,
    issueReminder: true,
    settlementReminder: true,
    invoicingEntity: null,
    amountExclTax: 0.00,
    amountInclTax: 0.00,
    taxRates:[],
    currency: null,
    recurrentCreationType: "",
    recurrentCreationLimit: null,
    avoirDate: null,
    bankAccount: null,
};

/**
 * @param {Object} formData 
 * @returns boolean indicating whether invoice form data is validated 
 */
export const isInvoiceFormValidated = (formData) => {
    if (!formData) return ["invoice_form_data_required"];

    const validationErrors = [];
    
    if(!formData.client) 
        validationErrors.push("invoice_client_required");
    
    if(!formData.label) 
        validationErrors.push("invoice_label_required");
    
    if(!formData.expectedDate) 
        validationErrors.push("invoice_expected_date_required");
    
    if(!formData.expectedSettlementDate) 
        validationErrors.push("invoice_expected_settlement_date_required");

    if(!formData.bankAccount) 
        validationErrors.push("bank_account_required");

    if(formData.expectedDate && formData.expectedSettlementDate && moment(formData.expectedDate).isAfter(moment(formData.expectedSettlementDate))) 
        validationErrors.push("invoice_expected_date_should_be_before_expected_settlement_date");
    
    if(!formData.status) 
        validationErrors.push("invoice_status_required");

    if(isEMISE(formData) && !formData.issueDate) 
        validationErrors.push("invoice_issue_date_required_for_emise_status");
    
    if(isEMISE(formData) && (
        ((formData.invoicingEntity != null) && formData.invoicingEntity.digital) || 
        (formData.invoicingEntity == null && formData.client.digital)
    ) &&
        (formData.invoiceOrder.reference === null)
    ) 
        validationErrors.push("invoice_order_reference_required_for_digital_client");

    if(isREGLEE(formData) && !formData.issueDate) 
        validationErrors.push("invoice_issue_date_required_for_reglee_status");
    
    if(isREGLEE(formData) && !formData.settlementDate) 
        validationErrors.push("invoice_settlement_date_required_for_reglee_status");
    
    if(isAVOIREE(formData) && !formData.issueDate) 
        validationErrors.push("invoice_issue_date_required_for_avoiree_status");

    if(isAVOIREE(formData) && !formData.avoirDate)
        validationErrors.push("invoice_avoir_date_required_for_avoiree_status");
    
    if(isREGLEE(formData) && !formData.settlementDate) 
        validationErrors.push("invoice_settlement_date_required_for_avoiree_status");

    if(formData.issueDate && moment(formData.issueDate).isAfter(moment()))
        validationErrors.push("invoice_issue_date_should_not_be_after_today");

    if(isREGLEE(formData) && moment(formData.settlementDate).isAfter(moment().add(7, 'days'))) 
        validationErrors.push("invoice_settlement_date_should_not_be_after_seven_days");
    
    if(formData.issueDate && formData.settlementDate && moment(formData.issueDate).isAfter(moment(formData.settlementDate))) 
        validationErrors.push("invoice_issue_date_should_be_before_settlement_date");

    if(formData.issueDate && formData.expectedSettlementDate && moment(formData.issueDate).isAfter(moment(formData.expectedSettlementDate))) 
        validationErrors.push("invoice_issue_date_should_be_before_expected_settlement_date");

    for (const item of formData.invoiceOrder.orderItems) {
        if (!item.description) {
            validationErrors.push("invoice_order_item_description_required");
            break;
        }

        if (item.description.length > 255) {
            validationErrors.push("invoice_order_item_description_too_long");
            break;
        }
    }

    return validationErrors;
};

/**
 * @param {Object} invoice orginal invoice object
 * @param {Object} formData new invoice form data 
 * @returns boolean indicating whether invoice and formData are the same
 */
export const isInvoiceFormSame = (invoice, formData) => {
    if (!invoice || !formData) {
        return false;
    }

    if (
        (sameObjectId(invoice.client, formData.client))
        && (sameObjectId(invoice.project, formData.project))
        && (sameObjectId(invoice.responsable, formData.responsable))
        && (invoice.issueReminder === formData.issueReminder)
        && (invoice.settlementReminder === formData.settlementReminder)
        && (sameObjectId(invoice.invoicingEntity, formData.invoicingEntity))
        && (invoice.label === formData.label)
        && (invoice.triggerAction === formData.triggerAction)
        && (invoice.issueDate === formData.issueDate)
        && (invoice.invoiceOrder.reference === formData.invoiceOrder.reference)
        && (invoice.expectedDate === formData.expectedDate)
        && (invoice.expectedSettlementDate === formData.expectedSettlementDate)
        && (sameObjectId(invoice.status, formData.status))
        && (invoice.settlementDate === formData.settlementDate)
        && (invoice.taxRates.length === formData.taxRates.length)
        && (invoice.invoiceOrder.orderItems.length === formData.invoiceOrder.orderItems.length)
        && (invoice.currency?.code === formData.currency?.code)
        && (sameObjectId(invoice.bankAccount, formData.bankAccount))
        && (sameObjectId(invoice.invoiceType, formData.invoiceType))
    ) {
        for (let i = 0; i < formData.invoiceOrder.orderItems.length; i++) {
            const x = formData.invoiceOrder.orderItems[i];
            const y = invoice.invoiceOrder.orderItems[i];
            if (
                x.reference !== y.reference
                || x.description !== y.description
                || x.quantity !== y.quantity
                || x.isPercent !== y.isPercent
                || x.unitPrice !== y.unitPrice
            ) {
                return false;
            }
        }
        for(let i = 0; i< formData.taxRates.length; i++){
            const x = invoice.taxRates[i];
            const y = formData.taxRates[i];
            if(x?.id !== y?.id){
                return false;
            }
        }

        return true;
    }
    return false;
};