import axios from "axios";
import { get } from "lodash";
import { AUTH_TOKEN_KEY } from "./constants";
import Swal from "sweetalert2";
import { t } from "../i18n";

export const init = (SERVER_API_URL, keycloak) => {
    const isKeycloakDisabled = typeof keycloak === "undefined" ? true : false;
    const instance = axios.create({
        baseURL: SERVER_API_URL,
    });
    if (isKeycloakDisabled) {
        instance.interceptors.request.use(async (request) => {
            const token = await localStorage.getItem(AUTH_TOKEN_KEY);
            if (token) {
                request.headers.common["Authorization"] = `Bearer ${token}`;
            }

            return request;
        });
    } else {
        const SwalRefreshAlert = {
            title: t("session_expired_title"),
            text: t("session_expired_details"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t("refresh"),
            cancelButtonText: t("cancel"),
            reverseButtons: true
        };

        keycloak.onTokenExpired = () => {
            Swal.fire(SwalRefreshAlert)
                .then((result) => {
                    if (result.value) {
                        keycloak.login();
                    }
                });
        };

        instance.interceptors.request.use(request => {
            if (keycloak.isTokenExpired(5)) {
                return Swal.fire(SwalRefreshAlert)
                    .then((result) => {
                        if (result.dismiss === Swal.DismissReason.cancel) {
                            throw new axios.Cancel(t("operation_canceled"));
                        } else if (result.value) {
                            return keycloak.login();
                        }
                    });
            }

            return keycloak
                .updateToken(5)
                .then(() => {
                    request.headers.common[
                        "Authorization"
                    ] = `Bearer ${keycloak.token}`;
                    return Promise.resolve(request);
                })
                .catch(() => {
                    return Swal.fire(SwalRefreshAlert)
                        .then((result) => {
                            if (result.dismiss === Swal.DismissReason.cancel) {
                                throw new axios.Cancel(t("operation_canceled"));
                            } else if (result.value) {
                                return keycloak.login();
                            }
                        });
                });
        });
    }

    // Response interceptor
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            const status = get(error, "response.status");
            if (status && error instanceof axios.Cancel) {
                return Promise.resolve({});
            }

            if (status >= 500 && status < 502) {
                Swal.fire({
                    icon: "error",
                    title: t("error_alert_title"),
                    text: t("error_alert_text"),
                    reverseButtons: true,
                    confirmButtonText: t("ok"),
                });
            }
            if (status >= 502) {
                Swal.fire({
                    type: "error",
                    title: t("error_alert_title"),
                    text: t("service_error_text"),
                    reverseButtons: true,
                    confirmButtonText: t("ok"),
                });
            }
            if (
                status === 401 &&
                (get(
                    error,
                    "response.data.detail",
                    "There is no details"
                ).includes("Full authentication") ||
                    get(
                        error,
                        "response.data.message",
                        "There is no message"
                    ) === "Expired token")
            ) {
                Swal.fire({
                    icon: "warning",
                    title: t("token_expired_alert_title"),
                    text: t("token_expired_alert_text"),
                    reverseButtons: true,
                    confirmButtonText: t("ok"),
                }).then(() => {
                    if (isKeycloakDisabled) {
                        localStorage.clear();
                        document.location.href = "/login";
                    }
                });
            }
            if (status === 404) {
                Swal.fire({
                    icon: "warning",
                    title: t("page_not_found"),
                    text: t("page_not_found_description"),
                    confirmButtonText: t("ok"),
                });
            }

            return Promise.reject(error);
        }
    );
    return instance;
};
