import React, { useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Box, Checkbox, Popper, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import { getAllProjects } from '../../../view/redux/actions/rt';


function ProjectSelect({
    projects,
    value,
    handleValueChange,
    variant = "standard",
    size = "medium",
    inputStyle, inputLabel,
    filtered = false,
    multiselect = false,
    checkmark = false,
    disabled = false
}) {

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector(({ invoicing }) => invoicing.filter);
    const projectsList = useSelector(({ rt }) => rt.projects);

    const sortOptions = (options) => {
        return options && options.sort((a, b) => a.status.id - b.status.id || a.name.localeCompare(b.name));
    };

    useEffect(() => {
        dispatch(getAllProjects(filtered));
    }, [filter.year, filter.month]); //eslint-disable-line react-hooks/exhaustive-deps

    const uniSelect = () => (
        <Autocomplete
            value={value}
            disabled={disabled}
            options={projects ? sortOptions(projects) : sortOptions(projectsList)}
            onChange={(e, val) => handleValueChange(val)}
            autoHighlight
            getOptionLabel={(project) => project.name}
            getOptionSelected={(option, value) => option.id === value?.id}
            renderOption={(project) => project.name}
            groupBy={(project) => project.status.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant={variant}
                    label={inputLabel || t('project')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_project_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper }}
        />
    );

    const multiSelect = () => (
        <Autocomplete
            multiple={true}
            disabled={disabled}
            value={value}
            options={projects ? sortOptions(projects) : sortOptions(projectsList)}
            onChange={(e, val) => handleValueChange(val)}
            getOptionLabel={project => project.name}
            getOptionSelected={(option, value) => option.id === value.id}
            groupBy={(project) => project.status.name}
            renderOption={(project) => (
                <Box style={{flexShrink: 0}} >
                    {
                        checkmark &&
                        <Checkbox 
                            size="small" 
                            checked={value.map(v => v.id).includes(project.id)} 
                        />
                    }
                    {project.name}
                </Box>
            )}
            renderTags={(value) => (
                <Typography 
                    noWrap={true}
                >
                    {value.map(o => o.name).join(' | ')}
                </Typography>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    variant={variant}
                    label={inputLabel || t('project')}
                    size={size}
                    style={inputStyle}
                />
            )}
            noOptionsText={t('no_project_found')}
            clearText={t('clear')}
            openText={t('open')}
            closeText={t('close')}
            classes={{ paper: classes.paper, option: classes.option }}
            className={classes.root}
            PopperComponent={props => (
                <Popper 
                    {...props} 
                    style={{minWidth: props.style.width}} 
                    placement="bottom-start"
                />
            )}
        />
    );

    return multiselect
        ? multiSelect()
        : uniSelect();

}

export default ProjectSelect;