import SettingsIcon from '@material-ui/icons/Settings';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { MANAGE_INVOICING_ROLE, ADMIN_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE } from '../../utils/constants';

export default [
    {
        name: "dashboard",
        path: "/dashboard",
        pathFormat: "/dashboard*",
        icon: DashboardIcon,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        clickable: true
    },
    {
        name: "invoicing_area",
        path: `/invoicing/${new Date().getFullYear()}`,
        pathFormat: `/invoicing/:year(.*)`,
        icon: ReceiptIcon,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        clickable: true
    },
    {
        name: "payments",
        path: `/payments/${new Date().getFullYear()}`,
        pathFormat: `/payments/:year(.*)`,
        icon: AttachMoneyIcon,
        permissions: [],
        roles: [MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE],
        clickable: true
    },
    {
        name: "administration",
        path: "/administration",
        pathFormat: "/administration*",
        icon: SettingsIcon,
        permissions: [],
        roles: [ADMIN_INVOICING_ROLE],
        clickable: false,
        expanded: false,
        items: [
            {
                name: "rtables",
                path: "/administration/reference-tables",
                pathFormat: "/administration/reference-tables*",
                permissions: [],
                roles: [],
                clickable: true
            },
            {
                name: "company_information",
                path: "/administration/company-information",
                pathFormat: "/administration/company-information*",
                permissions: [],
                roles: [],
                clickable: true
            },
            {
                name: "invoicing_config",
                path: "/administration/invoicing-config",
                pathFormat: "/administration/invoicing-config*",
                permissions: [],
                roles: [],
                clickable: true
            },
            {
                name: "access_control",
                path: "/administration/access-control",
                pathFormat: "/administration/access-control*",
                permissions: [],
                roles: [],
                clickable: true
            },
            {
                name: "global_audit",
                path: "/administration/global-audit",
                pathFormat: "/administration/global-audit*",
                permissions: [],
                roles: [],
                clickable: true
            }
        ]
    },
];
