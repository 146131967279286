import React, { useEffect, useState } from 'react';
import {
    Box,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Popover,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useStyles from './style';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceStatusColor } from '../../../utils/invoice-helper';
import moment from 'moment';
import { enMonthsLower } from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import FinancialDatePicker from '../FinancialDatePicker';
import ProjectSelect from '../../../App/components/ProjectSelect';
import { setInvoicingFilter } from '../../redux/actions/invoicing';
import YearInvoicingType from '../YearInvoicingType';
import InvoicingClientSelect from '../InvoicingClientSelect';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRangePicker from '../../../App/components/DateRangePicker';
import MultiSelect from '../../../App/components/MultiSelect';
import InvoicingEntitySelect from '../InvoicingEntitySelect';
import Checkbox from '@material-ui/core/Checkbox';
import { Autocomplete } from '@material-ui/lab';

function InvoicingFilters({ loading, onChange }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const invoiceStatusList = useSelector(({ rt }) => rt.invoiceStatus);
    const projectsList = useSelector(({ rt }) => rt.projects);
    const filter = useSelector(({ invoicing }) => invoicing.filter);
    const dispatch = useDispatch();
    const params = useParams();
    const [dateFilterAnchorEl, setDateFilterAnchorEl] = useState(null);
    const invoiceTypeList = useSelector(({ rt }) => rt.invoiceTypes);
    const currentCollaborator = useSelector(({collaborators}) => collaborators.currentCollaborator);

    const dispatchFilter = (f) => dispatch(setInvoicingFilter(f)); //eslint-disable-line react-hooks/exhaustive-deps

    const renderFilterDetails = () => {
        const _get = (type, afterName, beforeName, after, before) => {
            if(before && after) {
                return {
                    type: t(type), 
                    label: t("between_date_chip_title", {after: moment(after).format("DD/MM/yyyy"), before: moment(before).format("DD/MM/yyyy")}),
                    onDelete: () => dispatchFilter({ ...filter, [afterName]: null, [beforeName]: null})
                };
            }
            if(before) {
                return {
                    type: t(type), 
                    label: t("before_date_chip_title", {before: moment(before).format("DD/MM/yyyy")}),
                    onDelete: () => dispatchFilter({ ...filter, [beforeName]: null})
                };
            }
            if(after) {
                return {
                    type: t(type), 
                    label: t("after_date_chip_title", {after: moment(after).format("DD/MM/yyyy")}),
                    onDelete: () => dispatchFilter({ ...filter, [afterName]: null})
                };
            }

            return null;
        };

        const temp = [];
        if(filter.afterExpectedDate || filter.beforeExpectedDate){
            temp.push(_get(
                "expected_date", 
                "afterExpectedDate",
                "beforeExpectedDate",
                filter.afterExpectedDate, 
                filter.beforeExpectedDate
            ));
        }

        if(filter.afterExpectedSettlementDate || filter.beforeExpectedSettlementDate){
            temp.push(_get(
                "expected_settlement_date", 
                "afterExpectedSettlementDate",
                "beforeExpectedSettlementDate",
                filter.afterExpectedSettlementDate, 
                filter.beforeExpectedSettlementDate
            ));
        }

        if(filter.afterIssueDate || filter.beforeIssueDate){
            temp.push(_get(
                "issue_date", 
                "afterIssueDate",
                "beforeIssueDate", 
                filter.afterIssueDate, 
                filter.beforeIssueDate
            ));
        }

        if(filter.afterSettlementDate || filter.beforeSettlementDate){
            temp.push(_get(
                "settlement_date", 
                "afterSettlementDate",
                "beforeSettlementDate",
                filter.afterSettlementDate, 
                filter.beforeSettlementDate
            ));
        }

        return temp;
    };

    useEffect(() => {
        if (params.month && params.year) {
            dispatchFilter({
                ...filter,
                month: enMonthsLower.indexOf(params.month) + 1,
                year: parseInt(params.year),
            });
        } else if (params.year) {
            dispatchFilter({
                ...filter,
                month: null,
                year: parseInt(params.year),
            });
        }
    }, [params]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loading) {
            onChange();
        }
    }, [filter]); //eslint-disable-line react-hooks/exhaustive-deps

    const filterProjects = () => {
        if (filter.clients && filter.clients.length > 0 && filter.projectTypes && filter.projectTypes.length > 0) {
            const clients = filter.clients.map(c => c.code || c.name);
            const projectTypes = filter.projectTypes.map(t => t.id);

            return projectsList.filter(p => (
                !!p.client
                && ((!!p.client.code && clients.includes(p.client.code)) || (!!p.client.name && clients.includes(p.client.name)))
                && projectTypes.includes(p.type.id)
            ));
        } else if (filter.clients && filter.clients.length > 0) {
            const clients = filter.clients.map(c => c.code || c.name);
            
            return projectsList.filter(p => (
                !!p.client
                && ((!!p.client.code && clients.includes(p.client.code)) || (!!p.client.name && clients.includes(p.client.name)))
            ));
        } else if (filter.projectTypes && filter.projectTypes.length > 0) {
            const projectTypes = filter.projectTypes.map(t => t.id);
            return projectsList.filter(p => projectTypes.includes(p.type.id));
        } else {
            return projectsList;
        }
    };

    return (
        <>
            <Grid container item xs={12} style={{ marginBottom: "16px" }} justifyContent="space-between" alignItems="flex-end">
                <Grid item>
                    <FinancialDatePicker filter={filter} type="invoicing" />
                </Grid>
                <Grid container item xs={8} spacing={1} justifyContent="flex-end">
                    <Grid item xs={3}>
                        <InvoicingClientSelect
                            value={filter.clients}
                            handleValueChange={val => dispatchFilter({ ...filter, clients: val })}
                            variant="outlined"
                            size="small"
                            inputStyle={{
                                backgroundColor: "#fbfbfb",
                                borderRadius: 4,
                            }}
                            inputLabel={t('client')}
                            filtered={true}
                            multiselect={true}
                            checkmark={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InvoicingEntitySelect
                            value={filter.invoicingEntities}
                            handleValueChange={val => dispatchFilter({ ...filter, invoicingEntities: val })}
                            variant="outlined"
                            size="small"
                            inputStyle={{
                                backgroundColor: "#fbfbfb",
                                borderRadius: 4,
                            }}
                            inputLabel={t('invoicing_entity')}
                            filtered={true}
                            multiselect={true}
                            checkmark={true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            multiple={true}
                            value={filter.invoiceTypes}
                            options={invoiceTypeList}
                            onChange={(e, val) => dispatchFilter({ ...filter, invoiceTypes: val})}
                            autoHighlight
                            getOptionLabel={(type) => type.name}
                            getOptionSelected={(option, value) => option.id === value?.id}
                            renderOption={(type) => (
                                <Box style={{flexShrink: 0}} >
                                    <Checkbox 
                                        size="small" 
                                        checked={filter.invoiceTypes?.map(v => v.id).includes(type.id)} 
                                    />
                                    {type.name}
                                </Box>
                            )}
                            renderTags={(value) => (
                                <Typography 
                                    noWrap={true}
                                >
                                    {value.map(o => o.name).join(' | ')}
                                </Typography>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    label={t('invoice_type')}
                                    size="small"
                                    style={{
                                        backgroundColor: "#fbfbfb",
                                        borderRadius: 4,
                                    }}
                                />
                            )}
                            noOptionsText={t('no_type_found')}
                            clearText={t('clear')}
                            openText={t('open')}
                            closeText={t('close')}
                            classes={{ paper: classes.paper }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ProjectSelect
                            projects={filterProjects()}
                            value={filter.projects}
                            handleValueChange={val => dispatchFilter({ ...filter, projects: val })}
                            variant="outlined"
                            size="small"
                            inputStyle={{
                                backgroundColor: "#fbfbfb",
                                borderRadius: 4,
                            }}
                            inputLabel={t('project')}
                            filtered={true}
                            multiselect={true}
                            checkmark={true}
                        />
                    </Grid>
                </Grid>
            </Grid>          
            <Grid container item xs={12} justifyContent="space-between" alignItems="center" spacing={3}>
                <Grid container item xs className={classes.filterStart}>
                    <Grid item>
                        <YearInvoicingType filter={filter} type="invoicing" />
                    </Grid>
                </Grid>
                <Grid container item xs={7} spacing={6} justifyContent="flex-end" className={classes.filterMiddle}>
                    <Grid item container xs={4} alignItems="flex-end">
                        <FormGroup 
                            onChange={(e) => e.target.checked ? dispatchFilter({ ...filter, responsables: [currentCollaborator] }) : dispatchFilter({ ...filter, responsables: [] })}
                        >
                            <FormControlLabel 
                                checked={!!filter.responsables && filter.responsables.length === 1}
                                style={{margin: 0}}
                                control={<Switch size="small" />} 
                                label={t("my_invoices")}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item container xs={4} alignItems="flex-end">
                        <FormGroup 
                            onChange={(e) => e.target.checked ? dispatchFilter({ ...filter, electronic: true }) : dispatchFilter({ ...filter, electronic: false })}
                        >
                            <FormControlLabel 
                                checked={filter.electronic}
                                style={{margin: 0}}
                                control={<Checkbox style={{padding: "4px"}} size="small" />} 
                                label={t("electronic_invoices")}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>
                        {(invoiceStatusList.length > 0) &&
                            <MultiSelect
                                label={t('status_col')}
                                noOptionsLabel={t('no_status_found')}
                                value={filter.status && filter.status.map(s => s.name)}
                                onChange={(event) => dispatchFilter({ ...filter, status: event.target.value.map(x => invoiceStatusList.find(s => s.name === x)) })}
                                list={invoiceStatusList.map(s => ({id: s.id, label: s.name, code: s.code}))}
                                checkmark={true}
                                renderValue={selected => selected
                                    .map(name => invoiceStatusList.find(s => s.name === name))
                                    .map(s => (
                                        <span key={s.id}>
                                            <FiberManualRecordIcon
                                                style={{ color: getInvoiceStatusColor(s.code).color }}
                                                className={classes.statusIcon}
                                            />
                                            {s.name}
                                        </span>
                                    ))
                                    .reduce((prev, curr) => [prev, ' | ', curr])
                                }
                                renderLabel={(s) => (
                                    <>
                                        <FiberManualRecordIcon
                                            style={{ color: getInvoiceStatusColor(s.code).color }}
                                            className={classes.statusIcon}
                                        />
                                        {s.label}
                                    </>
                                )}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid item className={classes.filterEnd}>
                    <Tooltip 
                        title={t("dates_filter")}
                        placement="top"
                        arrow
                    >
                        <IconButton
                            className={classes.dateFilters}
                            onClick={(e) => setDateFilterAnchorEl(e.currentTarget)}
                        >
                            <DateRangeIcon />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        id="filter-more-popover"
                        open={Boolean(dateFilterAnchorEl)}
                        anchorEl={dateFilterAnchorEl}
                        onClose={() => setDateFilterAnchorEl(null)}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        className={classes.dateFilterPopover}
                    >
                        <Box p={1}>
                            <Typography 
                                className={classes.dateRangeLabel}
                                gutterBottom
                            >
                                {`${t("expected_date")}:`}
                            </Typography>
                            <DateRangePicker
                                start={filter.afterExpectedDate}
                                end={filter.beforeExpectedDate}
                                onStartChange={(v) => dispatchFilter({ ...filter, afterExpectedDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                                onEndChange={(v) => dispatchFilter({ ...filter, beforeExpectedDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                            />
                        </Box>
                        <Box p={1}>
                            <Typography 
                                className={classes.dateRangeLabel}
                                gutterBottom
                            >
                                {`${t("expected_settlement_date")}:`}
                            </Typography>
                            <DateRangePicker
                                start={filter.afterExpectedSettlementDate}
                                end={filter.beforeExpectedSettlementDate}
                                onStartChange={(v) => dispatchFilter({ ...filter, afterExpectedSettlementDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                                onEndChange={(v) => dispatchFilter({ ...filter, beforeExpectedSettlementDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                            />
                        </Box>
                        <Box p={1}>
                            <Typography 
                                className={classes.dateRangeLabel}
                                gutterBottom
                            >
                                {`${t("issue_date")}:`}
                            </Typography>
                            <DateRangePicker
                                start={filter.afterIssueDate}
                                end={filter.beforeIssueDate}
                                onStartChange={(v) => dispatchFilter({ ...filter, afterIssueDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                                onEndChange={(v) => dispatchFilter({ ...filter, beforeIssueDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                            />
                        </Box>
                        <Box p={1}>
                            <Typography 
                                className={classes.dateRangeLabel}
                                gutterBottom
                            >
                                {`${t("settlement_date")}:`}
                            </Typography>
                            <DateRangePicker
                                start={filter.afterSettlementDate}
                                end={filter.beforeSettlementDate}
                                onStartChange={(v) => dispatchFilter({ ...filter, afterSettlementDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                                onEndChange={(v) => dispatchFilter({ ...filter, beforeSettlementDate: v ? moment(v).format("yyyy-MM-DD") : null })}
                            />
                        </Box>
                    </Popover>
                </Grid>
            </Grid>
            <Grid container item xs={12} style={{margin: "8px -8px -8px -8px"}}>
                {renderFilterDetails().filter(c => !!c).map((c, idx) => (
                    <Chip 
                        key={idx}
                        size="small"
                        label={
                            <>
                                <b>{c.type}</b> {c.label}
                            </>
                        }
                        onDelete={c.onDelete}
                        style={{ margin: "8px" }}
                    />
                ))}
            </Grid>
        </>
    );

}

export default React.memo(InvoicingFilters);