import { LOGOUT_USER, SET_CURRENT_USER } from "../../constants";

const initialState = {
    loading: false,
    user: {},
    collaborator: {},
    error: undefined,
    userNotFound: false,
    collaboratorNotFound: false,

    // Novy builtin auth
    isAuthenticated: false,
    errorMessage: null,
    password_requested: false,
    password_changed: false,
};

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return setUser(
                state,
                action.response.user,
                action.response.loading,
                action.response.isAuthenticated,
                action.response.errorMessage
            );
        case LOGOUT_USER:
            return logout(state, action.keycloak);
        default:
            return state;
    }
};

const setUser = (state, user, loading, isAuthenticated, errorMessage) => {
    let modifiedUser;
    if (user && Object.keys(user).length === 0) {
        modifiedUser = {};
    } else {
        modifiedUser = { ...user, permissions: JSON.parse(user.permissions) };
    }

    return {
        ...state,
        loading: loading,
        isAuthenticated: isAuthenticated,
        errorMessage: errorMessage,
        user: modifiedUser,
    };
};

const logout = (state, keycloak) => {
    localStorage.clear();
    keycloak && keycloak.logout();
    state = Object.assign(
        {},
        {
            isAuthenticated: false,
            user: {},
        }
    );
    state.isAuthenticated = false;

    return state;
};

export default Auth;
